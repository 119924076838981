//import fonts scss
@import "./fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif !important;
  margin: 0;
  padding: 0;
  // background: rgb(247, 248, 250);
}
.chakra-step__separator {
  border-radius: 1rem;
  height: 3px !important;
}
.chakra-step__separator[data-status="complete"] {
  background: #42ce7c !important;
}

a {
  text-decoration: none;
}

/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
  // height: 100%;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  // background: var(--bg-color);
  position: relative;
  background-color: #f8f8f8;
  &.no-scroll {
    overflow: hidden;
  }
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.chakra-switch {
  .chakra-switch__track {
    &[data-checked] {
      background: #8f8fff;
    }
  }
}

.customDatePicker {
  .react-datepicker-wrapper {
    width: 100%;
    font-family: "Gilroy", sans-serif;

    .react-datepicker__input-container {
      width: 100%;

      input {
        width: 100%;
        height: 3rem;
        border-radius: 0.75rem;
        border: 1px solid #eef0f4;
        padding: 0 1rem;
        font-weight: 500;
        color: #150a40;
        font-size: 0.938rem;

        &.react-datepicker__input-container--invalid {
          border: 1px solid red;
        }
        &.isSmall {
          font-size: 0.875rem;
          height: 2.5rem;
        }
      }
    }
  }
}

.popoverDatePicker {
  .react-datepicker-popper {
    z-index: 9;
  }
  &.singleDatepicker {
    .react-datepicker {
      width: 300px;
    }
  }
  .react-datepicker {
    border: 1px solid #eef0f4;
    border-radius: 1rem;
    width: 382px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    padding-bottom: 1rem;

    .react-datepicker__navigation {
      .react-datepicker__navigation-icon {
        border: none;
        background: none;
        color: #000;
        &:hover {
          background: none;
        }
        &::before {
          height: 6px;
          width: 6px;
          font-weight: 300;
          border-width: 2px 2px 0 0;
          // border: none;
          // background: none;
        }
      }
    }
    .react-datepicker__month-container {
      position: relative;

      .react-datepicker__month {
        position: relative;
        .react-datepicker__day {
          position: relative;
          color: #727190;
          font-size: 0.875rem;

          &.react-datepicker__day--disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }

          &.react-datepicker__day--selected {
            background: #8f8fff;
            color: #fff;
            font-weight: 500;
            &:hover {
              background: #3b3882;
            }
          }
          &.react-datepicker__day--in-selecting-range {
            background: #8f8fff;
            color: #fff;
            font-weight: 500;
            &:hover {
              background: #3b3882;
            }
          }
        }
      }

      .react-datepicker__header {
        padding: 0rem 0 0rem;
        border: 0;
        background: none;

        .react-datepicker__current-month {
          font-size: 0.875rem;
          font-weight: 500;
        }
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            font-size: 0.75rem;
            font-weight: 500;
            color: #727190;
          }
        }
      }
    }
    .react-datepicker__time-container {
      border-left: 1px solid #eef0f4;

      .react-datepicker__header--time {
        background: white;
        border: none;
        padding: 1.5rem 0 0.875rem;
        .react-datepicker-time__header {
          font-size: 0.875rem;
          font-weight: 500;
        }
      }

      .react-datepicker__time {
        position: relative;
        .react-datepicker__time-box {
          position: relative;
          .react-datepicker__time-list {
            position: relative;
            .react-datepicker__time-list-item {
              color: #727190;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              font-size: 0.875rem;
              &:hover {
                background: #edf2f7;
              }
              &.react-datepicker__time-list-item--selected {
                background: #8f8fff;
                color: #fff;
                font-weight: 600;

                &:hover {
                  background: #3b3882;
                }
              }
            }
          }
        }
      }
    }
    .react-datepicker__children-container {
      // position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      // height: initial;
      // padding: 0;
      // margin: 0;
      // background: green;
    }
  }
}

.customPhone {
  width: 56px;
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      background: #fff;
      border-top-left-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
      height: 48px;
      width: 56px;
      border: none;
      border-right: 1px solid #eef0f4;

      // &:focus {
      //   outline: 0;
      // }
      .react-international-phone-country-selector-button__button-content {
        padding: 0 1rem;
        .react-international-phone-country-selector-button__flag-emoji {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  // .react-international-phone-input {
  //   width: 100%;
  //   border: 1px solid #ddd;
  //   background: #fff;
  //   color: #000;
  //   height: 48px;
  //   font-size: 1rem;
  //   font-weight: 500;
  //   border-top-right-radius: 0.75rem;
  //   border-bottom-right-radius: 0.75rem;
  // }

  &.customPhone-error {
    .react-international-phone-input-container {
      .react-international-phone-country-selector {
        .react-international-phone-country-selector-button {
          border: 1px solid red;
        }
      }
      .react-international-phone-input {
        border: 1px solid red !important;
      }
    }
  }
}

.appointmentPopoverDatepicker {
  &.isDisabled {
    // background: green;
    opacity: 0.4;

    &:hover {
      cursor: not-allowed;
    }

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        color: #727190;
        border: 0px solid #ddd;
        background: none;
        padding: 0;
        margin: 0;
        text-transform: capitalize;
        width: 100%;
        &:hover {
          cursor: pointer;
        }
        &.datePopoverDatepickerNoDate {
          color: #dfdfdf;
          text-transform: none;
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      color: #000;
      border: 1px solid #ddd;
      background: #fff;
    }
  }
}

.react-datepicker {
  font-family: "Gilroy", sans-serif;
  overflow: hidden;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;

  &:hover {
    border-right-color: #a0aec0;
  }
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;

  &:hover {
    border-left-color: #a0aec0;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__header {
  border-radius: 0;
  background: #f7fafc;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container {
  .react-datepicker__time {
    .react-datepicker__time-box {
      ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        margin: 0 1px 0 0;
        height: auto;
        padding: 7px 10px;

        &:hover {
          background: #edf2f7;
        }
      }
    }
  }
}

.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #3182ce;
  font-weight: normal;

  &:hover {
    background: #2a69ac;
  }
}
// .swiper.courseInfoSlider .swiper-pagination {
//   bottom: 0px;
// }
.swiper.courseInfoSlider {
  padding-bottom: 0.5rem;
}

// CUSTOM PLACEHOLDER COLOR

// /* For Webkit browsers */
// input::-webkit-input-placeholder {
//   color: #c6cbd4;
// }

// /* For Firefox */
// input:-moz-placeholder {
//   color: #c6cbd4;
// }

// /* For Firefox v19+ */
// input::-moz-placeholder {
//   color: #c6cbd4;
// }

// /* For Internet Explorer */
// input:-ms-input-placeholder {
//   color: #c6cbd4;
// }

// /* Generic placeholder */
// input::placeholder {
//   color: #c6cbd4;
// }

// POPOVERS
.chakra-popover__poppe {
  z-index: 9999999999;
}

.chakra-popover__content.teeth-popover-content {
  max-width: 100%;
}

// STICKY FIRST TABLE

table {
  &.chakra-table.sticky {
    thead {
      tr {
        th {
          // padding: 1rem 1rem;
          white-space: nowrap;

          &:first-child {
            padding-left: 2rem;
            position: absolute;
            width: 222px;
            border-right: 1px solid #eef0f4;

            @media (max-width: 768px) {
              padding-left: 1rem;
            }
          }

          &:last-child {
            text-align: right;
            padding-right: 2rem;
            @media (max-width: 768px) {
              padding-right: 1rem;
            }
          }

          &:nth-child(2) {
            @media (max-width: 768px) {
              padding-left: calc(222px + 2rem);
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          height: 80px;
          max-height: 80px;
          &:first-child {
            padding-left: 2rem;
            position: absolute;
            width: 222px;
            background: white;
            z-index: 1;
            pointer-events: none;
            // box-shadow: 1px 0px 16px rgba(0, 0, 0, 0.05);
            border-right: 1px solid #eef0f4;

            @media (max-width: 768px) {
              padding-left: 1rem;
            }
          }
          &:last-child {
            padding-right: 2rem;
            @media (max-width: 768px) {
              padding-right: 1rem;
            }
          }
          &:nth-child(2) {
            @media (max-width: 768px) {
              padding-left: calc(222px + 2rem);
            }
          }
        }
      }
    }
  }
}

// CUSTOM PADDING TABLE
table {
  &.chakra-table {
    thead {
      tr {
        th {
          // padding: 1rem 1rem;
          white-space: nowrap;
          &:first-child {
            padding-left: 2rem;

            @media (max-width: 768px) {
              padding-left: 1rem;
            }
          }
          &:last-child {
            // font-size: 0;
            text-align: right;
            padding-right: 2rem;
            @media (max-width: 768px) {
              padding-right: 1rem;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          // border-bottom: none;
          // padding: 1rem 1rem;
          &:first-child {
            padding-left: 2rem;
            // padding-right: 0;
            @media (max-width: 768px) {
              padding-left: 1rem;
            }
          }
          &:last-child {
            padding-right: 2rem;
            @media (max-width: 768px) {
              padding-right: 1rem;
            }
          }
        }
      }
    }
  }
  &.multi {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 1rem;
            padding-right: 0rem;
          }
          &:last-child {
            padding-right: 1rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 1rem;
            padding-right: 0rem;
          }
          &:last-child {
            padding-right: 1rem;
          }
        }
      }
    }
  }
  &.narrow {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 1rem;
          }
          &:last-child {
            padding-right: 1rem;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 1rem;
          }
          &:last-child {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

// CUSTOM AVATAR FONT SIZE
.chakra-avatar {
  .chakra-avatar__initials {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

// CUSTOM PROGRESS BAR COLOR
.attendeesTotal {
  div {
    background-color: #917df7;
  }
}

// DO NOT DISPLAY UPLOAD INPUT
#file-upload {
  display: none;
}
#file-upload2 {
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

.customScrollbar {
  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f6f1ff;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dfd0fd;
    border-radius: 180px;
  }
}

/* .side-menu{
  position: fixed;
  background: #000;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  transition: width .2s ease-in;
  z-index: 99;
} */

/* .side-menu.inactive{
  width: 80px;
} */

/* .side-menu .top-section{
  position: relative;
}

.side-menu .top-section .logo{
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
}
.side-menu .top-section .logo img{
  max-width: 100%;
  max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn{
  color: #666;
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
} */

.side-menu.inactive .top-section .toggle-menu-btn {
  right: -50px;
}

.side-menu .search-controller {
  color: #fff;
  position: relative;
}

.side-menu .search-controller .search-btn {
  width: 40px;
  height: 35px;
  position: absolute;
  background: transparent;
  border: 0;
  font-size: 20px;
  color: #666;
}

.side-menu .search-controller input[type="text"] {
  border: 0;
  outline: none;
  height: 35px;
  background: #333;
  border-radius: 5px;
  display: block;
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  color: #666;
}

/* .side-menu .main-menu .menu-item{
  color: #333;
  text-decoration: none;
  font-size: 15px;
  display: block;
  font-weight: 600;
  cursor: pointer;
} */

/* .side-menu .main-menu .menu-item.active{
  background: #333;
  border-radius: 5px;
  color: #fff;
} */

/* .side-menu .main-menu{
  margin: 5px 0;
  position: relative;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
} */
/* not required any more */
/* .side-menu.inactive .main-menu{
 
} */

.side-menu .main-menu .menu-item .menu-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
}

.side-menu .main-menu .menu-item span {
  position: absolute;
  display: inline-block;
  line-height: 40px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu .main-menu .sub-menu {
  color: #333;
  margin-left: 20px;
  border-left: 1px solid #e8ebf2;
  box-sizing: border-box;
  padding-left: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
  list-style: none;
}

.side-menu .main-menu .sub-menu.active {
  max-height: 500px;
  overflow: auto;
}

.side-menu .main-menu .sub-menu a {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #9393ab;
  box-sizing: border-box;
  padding: 12px 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: 0.2s all;
}
.side-menu .main-menu .sub-menu a:hover {
  background: #f8f8f8;
  padding-left: 12px;
  color: #2a364e;
  transition: 0.2s all;
}

.side-menu .main-menu .sub-menu a.active {
  /* change in background, not recorded in video */
  background: #f8f8f8;
  display: block;
  color: #2a364e;
  font-weight: 600;
  padding-left: 12px;
}

.side-menu-footer {
  width: 100%;
  background: #333;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}

.side-menu-footer .avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}
.side-menu-footer .avatar img {
  max-width: 100%;
}

.side-menu-footer .user-info {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
  position: absolute;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .side-menu-footer .user-info {
  opacity: 1;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu-footer .user-info h5 {
  font-size: 15px;
}

.side-menu-footer .user-info p {
  font-size: 14px;
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #333;
}

.container {
  margin-left: 300px;
  transition: margin-left 0.2s ease-in;
  padding: 50px;
  box-sizing: border-box;
}

.container.inactive {
  margin-left: 80px;
}
